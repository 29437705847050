import Image from 'next/image';
import React, {FC, useEffect, useRef, useState} from 'react';

import {TypoComponentsTeaserSlider} from 'lib/api/strapi';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import StrapiLink from 'components/basic-components/StrapiLink';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import styles from './TeaserSlider.module.scss';

const TeaserSlider: FC<TypoComponentsTeaserSlider> = ({slides}) => {
  const wrapperRef = useRef(null);

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    const container = wrapperRef.current;

    const handleScroll = () => {
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth - 1);
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollLeft = () => {
    const container = wrapperRef.current;
    container.scrollBy({
      left: -500,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    const container = wrapperRef.current;
    container.scrollBy({
      left: 500,
      behavior: 'smooth',
    });
  };

  return (
    <ContentWrapper sx={{position: 'relative'}}>
      <Box className={styles.wrapper} ref={wrapperRef}>
        {slides?.map((slide, index) => {
          return (
            <Box
              className={styles.slideWrapper}
              sx={{
                marginRight: {
                  xxs: index === slides.length - 1 ? 0 : '10px',
                  lg: index === slides.length - 1 ? 0 : '2%',
                },
              }}
              key={slide.id}
            >
              <StrapiLink className={styles.linkWrapper} linkProps={slide.link}>
                <Box className={styles.background} />
                <Image
                  className={styles.image}
                  src={slide?.image?.data?.attributes?.url}
                  alt=""
                  sizes="(min-width: 850px) 31vw, 286px"
                  height={slide?.image?.data?.attributes?.height}
                  width={slide?.image?.data?.attributes?.width}
                />
                <Box className={styles.imageContent}>
                  <Typography
                    sx={{typography: {xxs: 'footerCards', lg: 'h2'}}}
                    className={styles.name}
                  >
                    {slide?.name}
                  </Typography>
                  <Box className={styles.link}>
                    <Typography sx={{lineHeight: '24px', marginRight: '5px'}} variant="buttonCards">
                      {slide?.link?.linkText}
                    </Typography>
                    <CustomIcon name="arrow" className={styles.icon} />
                  </Box>
                </Box>
              </StrapiLink>
            </Box>
          );
        })}
      </Box>
      {showLeftButton && (
        <Box onClick={scrollLeft} className={styles.scrollButtonLeft}>
          <CustomIcon
            name="sliderArrow"
            style={{transform: 'scaleX(-1)'}}
            className={styles.sliderIcon}
          />
        </Box>
      )}
      {showRightButton && (
        <Box onClick={scrollRight} className={styles.scrollButtonRight}>
          <CustomIcon name="sliderArrow" className={styles.sliderIcon} />
        </Box>
      )}
    </ContentWrapper>
  );
};

export default withErrorBoundary(TeaserSlider, ' TeaserSlider');
